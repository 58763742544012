export const ENDPOINTS_CONFIG = {
  api: {
    login: "/users/login/",
    register: "/users/register/",
    users: "/users/list/",
    profile: "/users/profile/",
    portfolio: "/users/portfolio/",
    userProfile: "/users/",
    skills: "/users/skills_change/",
    recover: "/users/password_reset/",
    reset: "/users/password_reset_confirm/",
    changePassword: "/users/change_password/",
    activateAccount: "/users/activate/",
    userList: "/users/listfull/",
    blackList: "/users/blacklist/",
    addToBlackList: "/users/blacklist/add/",
    removeOfBlackList: "/users/blacklist/remove/",
    chats: {
      list: "/chats/",
    },
    ad: {
      ad: "/ad/ads/",
      ads: "/ad/ads/",
      respond: "/ad/ads/response/",
      categories: "/ad/categories_change/",
      types: "/ad/types_change/",
      myOrders: "/ad/users/ads/",
    },
  },
};
